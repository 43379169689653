import regex from '@utils/regex';
import Utils from '@utils/utils';
import React, { Dispatch, SetStateAction } from 'react';
import { UseFormMethods } from 'react-hook-form';
import ContactService from '@pages/Contact/contact.service';
import { useStateMachine } from 'little-state-machine';
import { ConnectForm } from '@/common/components/form';
import Label from '@/common/components/form/Label';
import { PreferredMethodOfContact, ProspectSource } from '@/types';
import { Field, OptionalLegend } from '@/common/components/form/Field';
import ErrorMessage from '@/common/components/form/ErrorMessage';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { PageID } from '@/types/enum/pageID';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  preferredMethodOfContact: PreferredMethodOfContact,
  showEmailError: boolean,
  setShowEmailError: Dispatch<SetStateAction<boolean>>,
  emailUserInputValue: string,
  setEmailUserInputValue: Dispatch<SetStateAction<string>>,
  prospectSource: ProspectSource,
  emailError: boolean,
  formMethods: UseFormMethods<InformationFormProps>,
  setPhoneError: Dispatch<SetStateAction<boolean>>,
  setEmailError: Dispatch<SetStateAction<boolean>>,
  localStep: PageID,
  shouldBeDisabled?: boolean,
  cancelProceedAttempt?: () => void
}

export const Email: React.FC<Props> = ({
  preferredMethodOfContact,
  showEmailError,
  setShowEmailError,
  emailUserInputValue,
  setEmailUserInputValue,
  emailError,
  prospectSource,
  formMethods,
  setPhoneError,
  setEmailError,
  localStep,
  shouldBeDisabled,
  cancelProceedAttempt
}) => {
  const { state } = useStateMachine();
  const { featuresActivated } = state;
  const isFieldDisabled = shouldBeDisabled || (localStep === PageID.INFORMATION && featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && preferredMethodOfContact === PreferredMethodOfContact.EMAIL);
  return (
    <ConnectForm>
      {({ register, t, getValues }: UseFormMethods<InformationFormProps> & TranslationFunction) => (
        <>
          <Label htmlFor="email">{t('dcc.email')}</Label>
          {preferredMethodOfContact === PreferredMethodOfContact.PHONE
          && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
          <Field
            name="email"
            id="email"
            type="email"
            ref={register({
              required: preferredMethodOfContact !== PreferredMethodOfContact.PHONE,
              pattern: regex.email,
            })}
            defaultValue={emailUserInputValue}
            hasError={emailError && showEmailError}
            autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'email'}
            onBlur={() => {
              ContactService.handleEmailBlur(setShowEmailError);
              ContactService.checkInputFormat(formMethods, setPhoneError, setEmailError, preferredMethodOfContact);
            }}
            onFocus={() => ContactService.handleEmailFocus(setShowEmailError, formMethods, cancelProceedAttempt)}
            maxLength={100}
            onKeyDown={(event) => ContactService.blurField(event)}
            onChange={() => setEmailUserInputValue(getValues().email)}
            disabled={isFieldDisabled}
            readOnly={prospectSource === ProspectSource.QP_WIRE}
          />
          {emailError && showEmailError && <ErrorMessage>{t('dcc.mailErrorDetailed')}</ErrorMessage>}
        </>
      )}
    </ConnectForm>
  );
};
