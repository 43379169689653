import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import React, { ChangeEvent, RefObject } from 'react';
import CrossIcon from '@assets/img/icons/CrossIcon';
import { useStateMachine } from 'little-state-machine';
import screenSizes from '@utils/screenSizes';

export enum SearchType {
  FORM = 'FORM',
}

export enum Widget {
  DEFAULT = 'CARET',
  CROSS = 'CROSS',
}

type CommonProps = {
  id?: string,
  placeholder?: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  onCrossClick: () => void,
  widget?: Widget
}

type DefaultProps = {
  value?: string,
  brandCode?: string,
  newSize?: number,
  windowWidth?: number;
}

type FormProps = {
  name?: string,
  autoComplete?: string,
  parentRef?: ((instance: HTMLInputElement) => void) | RefObject<HTMLInputElement>,
  searchType?: SearchType,
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void,
  defaultValue?: string,
  disabledPrefix?: boolean,
}

const getTextWidth = (text: string, font: string, brandCode: string) => {
  const element = document.createElement('canvas');
  const context = element.getContext('2d');
  if (context !== null) {
    context.font = font;
    const newText = ['BV', 'YSL', 'BAL'].includes(brandCode) ? text.toUpperCase() : text;
    const metrics = context.measureText(newText);
    return metrics.width;
  }
  return null;
};

const resizeText = (text: string, brandCode: string, windowWidth: number) => {
  if (text.length !== 0) {
    const searchBarWidth = windowWidth >= screenSizes.ipad.widthNumber ? 0.66 * windowWidth : 0.85 * windowWidth;
    let size = 1;
    const testTextWidth = (fontWeight: string) => {
      let fontSize = getTextWidth(text, `${fontWeight} ${size}pt sans-serif`, brandCode);
      while (fontSize !== undefined && fontSize !== null && fontSize < 0.9 * searchBarWidth) {
        size += 0.5;
        fontSize = getTextWidth(text, `${fontWeight} ${size}pt sans-serif`, brandCode);
      }
      return size;
    };
    size = ['BV', 'YSL'].includes(brandCode) ? testTextWidth('bold') + 1 : testTextWidth('normal');
    return Math.min(size > 1 ? size - 0.5 : 1, 20);
  }
  return 0;
};

const SearchBar = (props: CommonProps & DefaultProps & FormProps) => {
  const { onCrossClick, disabledPrefix, ...searchProps } = props;
  const { state } = useStateMachine();
  const { brand } = state;

  const realPlaceholder = searchProps.placeholder ?? '';
  const newSize = props.windowWidth && resizeText(realPlaceholder, brand.code, props.windowWidth);

  searchProps.placeholder = '';
  searchProps.newSize = newSize;
  searchProps.brandCode = brand.code;

  if (newSize !== 0) {
    searchProps.placeholder = realPlaceholder;
  }

  return (
    <Wrapper>
      {searchProps.searchType === SearchType.FORM
        ? <SearchForm {...searchProps} ref={searchProps.parentRef} disabled={disabledPrefix} />
        : (
          <>
            <Search {...searchProps} />
            <CrossButton onClick={onCrossClick} />
          </>
        )}
    </Wrapper>
  );
};

type CrossButtonProps = {
  onClick: () => void
}

const CrossButton = ({ onClick }: CrossButtonProps) => (
  <ResetSearchBarButton onClick={onClick} type="button">
    <CrossIcon />
  </ResetSearchBarButton>
);

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })};
`;

const Search = styled.input.attrs((props: CommonProps & DefaultProps & FormProps) => ({
  type: 'text',
  brandCode: props.brandCode,
  newSize: props.newSize,
}))`
  display: inline-block;
  margin: 30px 0 20px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid;
  padding-bottom: 6px;
  width: 100%;
  font-size: 20pt;
  border-radius: 0;

  ::placeholder {
     color: ${(props) => props.theme.colors.COMMON.searchBarPlaceholder.color};
     font-size: ${(props) => props.newSize}pt;
  }
  
  ${theme('brand', {
    YSL: css`
      text-transform: uppercase;
      font-weight: bold;
    `,
    BAL: css`
      text-transform: uppercase;      
    `,
    BV: css`
      text-transform: uppercase;
      font-weight: bold;    
    `,
  })};
`;

const SearchForm = styled.input.attrs(() => ({
  type: 'text',
}))`
  min-height: 38px;
  width: 100%;
  border: none;
  // for the phone prefix search bar to be black when disabled
  &:disabled {
    color: ${(props) => props.theme.colors.COMMON.field.disabled.color};
  }
`;

const ResetSearchBarButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 5px 0 0 0;
`;

export default SearchBar;
