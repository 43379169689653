import regex from '@utils/regex';
import { UseFormMethods } from 'react-hook-form';
import React, { Dispatch, SetStateAction } from 'react';
import Utils from '@utils/utils';
import { PreferredMethodOfContact } from '@/types';
import { InformationFormProps, KeyOfInformationFormProps } from '@/types/form/informationFormProps';
import mp from '@/services/mixpanel/mixpanel.service';

export default class ContactService {
  static checkInputFormat = (formMethods: UseFormMethods<InformationFormProps>, setPhoneError: Dispatch<SetStateAction<boolean>>, setEmailError: Dispatch<SetStateAction<boolean>>, preferredMethodOfContact: PreferredMethodOfContact) => {
    const phoneInput = formMethods.getValues('phoneNum');
    const phoneFormat = regex.phone;
    const emailInput = formMethods.getValues('email');
    const emailFormat = regex.email;
    let emailErrorReturn;
    let phoneErrorReturn;
    if (phoneInput && phoneFormat.exec(phoneInput)) {
      setPhoneError(false);
      phoneErrorReturn = false;
    } else {
      setPhoneError(true);
      phoneErrorReturn = true;
    }
    if (emailInput && emailFormat.exec(emailInput)) {
      setEmailError(false);
      emailErrorReturn = false;
    } else {
      setEmailError(true);
      emailErrorReturn = true;
    }

    // send Mixpanel errors
    if (phoneInput && !phoneFormat.exec(phoneInput)) mp.wrongPhoneFormat();
    if (emailInput && !emailFormat.exec(emailInput)) mp.wrongEmailFormat();

    if (preferredMethodOfContact === 'EMAIL' && !phoneInput) {
      setPhoneError(false);
      phoneErrorReturn = false;
    }
    if (preferredMethodOfContact === 'PHONE' && !emailInput) {
      setEmailError(false);
      emailErrorReturn = false;
    }
    return { email: emailErrorReturn, phoneNum: phoneErrorReturn };
  };

  static handleEmailBlur = (setShowEmailError: Dispatch<SetStateAction<boolean>>) => setShowEmailError(true);

  static handleEmailFocus = (setShowEmailError: Dispatch<SetStateAction<boolean>>, formMethods: UseFormMethods<InformationFormProps>, cancelProceedAttempt?: () => void) => {
    setShowEmailError(false);
    formMethods.clearErrors('duplicationAlert');
    formMethods.clearErrors('invalidEmailAlert');
    if (cancelProceedAttempt) cancelProceedAttempt();
  };

  static handlePhoneBlur = (setShowPhoneError: Dispatch<SetStateAction<boolean>>) => setShowPhoneError(true);

  static handlePhoneFocus = (formMethods: UseFormMethods<InformationFormProps>, setShowPhoneError: Dispatch<SetStateAction<boolean>>, cancelProceedAttempt?: () => void) => {
    setShowPhoneError(false);
    formMethods.clearErrors('duplicationAlert');
    if (cancelProceedAttempt) cancelProceedAttempt();
  };

  static avoidAlphaCharacters = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!(Utils.isDigit(event.key) || Utils.isAllowedKey(event.key))) {
      event.preventDefault();
    }
  };

  static removeAlphaCharacters = (event: React.ChangeEvent<HTMLInputElement>, elementName: KeyOfInformationFormProps, formMethods: UseFormMethods<InformationFormProps>) => {
    const valueWithoutAlphaCharacters = event.target.value.replace(/[^\d]/g, '');
    if (valueWithoutAlphaCharacters !== event.target.value) {
      formMethods.setValue(elementName, valueWithoutAlphaCharacters);
    }
  };

  static changePreferredMethodOfContact = async (formMethods: UseFormMethods<InformationFormProps>, setPhonePrefixLabel: Dispatch<SetStateAction<string>>, phonePrefixLabel: string, setPreferredMethodOfContact: Dispatch<SetStateAction<PreferredMethodOfContact>>, setEmailError:Dispatch<SetStateAction<boolean>>, setPhoneError: Dispatch<SetStateAction<boolean>>, cancelProceedAttempt: () => void) => {
    setPhonePrefixLabel(phonePrefixLabel);
    const radioButtonState = formMethods.getValues().preferredMethodOfContact;
    setPreferredMethodOfContact(radioButtonState);
    const phoneInput = formMethods.getValues('phoneNum');
    const emailInput = formMethods.getValues('email');
    if (radioButtonState === 'PHONE' && !emailInput) {
      setEmailError(false);
    }
    if (radioButtonState === 'EMAIL' && !phoneInput) {
      setPhoneError(false);
    }
    formMethods.unregister('email');
    formMethods.unregister('prefixCountry');
    formMethods.unregister('phoneNum');
    cancelProceedAttempt();
    await formMethods.trigger();
  };

  static setShowErrorIfFormError = (formMethods: UseFormMethods<InformationFormProps>, setShowErrorFunction: Dispatch<SetStateAction<boolean>>, formFieldName: 'email' | 'phoneNum', setPhoneError: Dispatch<SetStateAction<boolean>>, setEmailError: Dispatch<SetStateAction<boolean>>, preferredMethodOfContact: PreferredMethodOfContact) => {
    const isError: boolean = ContactService.checkInputFormat(formMethods, setPhoneError, setEmailError, preferredMethodOfContact)[formFieldName];
    if (formMethods.getValues(formFieldName)?.length > 0) {
      if (isError) {
        setShowErrorFunction(true);
      }
    }
  };

  static blurField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };
}
