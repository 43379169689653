import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import IdentityService from '@pages/Identity/identity.service';
import Utils from '@utils/utils';
import { PhoneAndEmail } from '@pages/Contact/components/PhoneAndEmail';
import { ZipCode } from '@pages/Address/components/ZipCode';
import { City } from '@pages/Address/components/City';
import { Street } from '@pages/Address/components/Street';
import { PoBox } from '@pages/Address/components/PoBox';
import { SectionTitle } from '@pages/Information/information.styles';
import InformationService from '@pages/Information/information.service';
import ConfirmModal from '@common/components/modals/ConfirmModal';
import EmailValidityUtils from '@utils/EmailValidity.utils';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import PageContainer from '@/common/components/containers/PageContainer';
import { Title } from '@/common/components';
import { Label } from '@/common/components/form';
import { ButtonContainer, FormContainer } from '@/common/components/containers';
import {
  updateAlreadyRegistered,
  updateBrandCountryConfiguration,
  updateCustomerInformation,
  updateDateSendingOtp,
  updateErrorTypeOnError,
  updateIsSecondPointOfContactDisabled
} from '@/littleStateMachine/actions';
import { Field } from '@/common/components/form/Field';
import { Country, PreferredMethodOfContact, TYPE_DROPDOWN } from '@/types';
import mp from '@/services/mixpanel/mixpanel.service';
import { FullPageLoader } from '@/common/components/Loader';
import { IdentityForm } from '@/common/components/form/information/IdentityForm';
import FormHeader from '@/common/components/form/FormHeader';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { ThemeType } from '@/types/enum/themeType';
import ErrorMessage from '@/common/components/form/ErrorMessage';
import { ErrorType } from '@/types/brandedTokenError';
import { CustomerCheckResponseEnum } from '@/types/api';

const Information: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.INFORMATION);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state and actions from store
  const { actions, state } = useStateMachine({ updateBrandCountryConfiguration, updateErrorTypeOnError, updateCustomerInformation, updateAlreadyRegistered, updateDateSendingOtp, updateIsSecondPointOfContactDisabled });
  const { brand, customer, prospectSource, flow, countries, translations, languages, alreadyRegistered, featuresActivated, mixPanelToken, actualToken, salesAdvisor } = state;
  const shouldDisplayAddress = featuresActivated.IS_ADDRESS_PAGE_DISPLAYED;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  useThemeColor(brand, ThemeType.FORM);

  const customerCountryCode = customer.customerContact.address.country;
  const customerStateCode = customer.customerContact.address.state;
  const currentCountry = countries.find((country: Country) => country.code === customer.customerContact.address.country);
  const statesFromCurrentCountry = currentCountry ? currentCountry.states : [];
  const { isSecondPointOfContactDisabled } = customer.customerContact;

  // current page
  const localStep = PageID.INFORMATION;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // As customerContact.phone is an array, we need to check it is not empty to retrieve phone data
  const { phone } = customer.customerContact;
  const emailLocal = customer.customerContact.email;
  const prefixCountryLocal = phone.prefixCountry ?? '';
  const number = phone.number || '';

  // local states
  const phoneCountryInitValueDefault = prefixCountryLocal || customer.customerContact.address.country;
  const { phonePrefixInitValue, phoneCountryInitValue } = Utils.getPhonePrefixAndCountry(phoneCountryInitValueDefault, countries);
  const [phonePrefixLabelLocal, setPhonePrefixLabelLocal] = useState(`${phonePrefixInitValue} (${phoneCountryInitValue})`);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [phoneUserInputValue, setPhoneUserInputValue] = useState(number);
  const [emailUserInputValue, setEmailUserInputValue] = useState(emailLocal || '');
  const [preferredMethodOfContactLocal] = useState(customer.customerContact.preferred);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [shouldEmailBeDisabled, setShouldEmailBeDisabled] = useState(preferredMethodOfContactLocal === PreferredMethodOfContact.PHONE && isSecondPointOfContactDisabled);
  const [shouldPhoneBeDisabled, setShouldPhoneBeDisabled] = useState(preferredMethodOfContactLocal === PreferredMethodOfContact.EMAIL && isSecondPointOfContactDisabled);

  const [showDateError, setShowDateError] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleCloseModal = async () => {
    setIsModalOpened(false);
  };

  const translatedCountries = countries
    .map((country: Country) => ({ ...country, name: t(`country.${country.code.toLocaleLowerCase()}`) }))
    .filter((country: Country) => country.phonePrefix !== undefined)
    .sort((a: Country, b: Country) => a.name.localeCompare(b.name));

  const customerCountry = countries.find((country: Country) => country.code === customer.customerContact.address.country);
  const titleTranslationKey = alreadyRegistered ? 'aboutClient.edit' : 'aboutClient.create';

  // Prepare the form
  const formMethods = useForm<InformationFormProps>({ mode: 'onChange' });
  const { setValue } = formMethods;
  const { isSubmitting, isValid } = formMethods.formState;
  const { duplicationAlert, invalidEmailAlert } = formMethods.errors;

  useEffect(() => {
    (async function checkSecondPointOfContact() {
      // disable second point of contact if it is certified
      if (InformationService.hasSecondPointOfContact(preferredMethodOfContactLocal, number, emailLocal)) {
        const { cdbResult, oktaResult } = await InformationService.checkSecondPointOfContact(preferredMethodOfContactLocal, customer, number, phonePrefixInitValue, emailLocal, brand, token);
        const { isExistingByContactInCDB, isUniqueByContactInCDB, isExistingByOktaSocialLoginInCDB, isUniqueByOktaSocialLoginInCDB } = cdbResult;
        const { contactCertified } = oktaResult;

        // Update mix-panel super properties
        await mp.updateCustomerFlowGlobally(actualToken);

        if ((isExistingByContactInCDB && isExistingByOktaSocialLoginInCDB) && isUniqueByContactInCDB && isUniqueByOktaSocialLoginInCDB && contactCertified) {
          if (preferredMethodOfContactLocal === PreferredMethodOfContact.EMAIL) {
            setShouldPhoneBeDisabled(true);
          }
          if (preferredMethodOfContactLocal === PreferredMethodOfContact.PHONE) {
            setShouldEmailBeDisabled(true);
          }
        }
      }
      setIsLoading(false);
    }());
  }, []);

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
  }, [navigate]);

  const onSubmit = formMethods.handleSubmit(async ({
    title = '',
    firstName,
    lastName,
    furiganaFirstName,
    furiganaLastName,
    day,
    month,
    year,
    searchFieldNationality = '',
    phonePrefixLabel,
    phoneNum,
    email,
    street,
    city,
    zipcode,
    pobox,
  }) => {
    const { prefixCountry, phonePrefix } = InformationService.splitPrefixAndCountryFromPrefixLabel(phonePrefixLabel) || InformationService.splitPrefixAndCountryFromPrefixLabel(phonePrefixLabelLocal);
    const informationInfos = {
      title,
      firstName,
      lastName,
      furiganaFirstName,
      furiganaLastName,
      day: String(day),
      month: String(month),
      year: String(year),
      searchFieldNationality: IdentityService.getCountryCodeFromNationalityTranslation(searchFieldNationality, translations),
      prefixCountry,
      phonePrefix,
      phoneNum,
      email,
      street,
      city,
      zipcode,
      pobox,
    };
    if (InformationService.hasNonCertifiedSecondPointOfContact(preferredMethodOfContactLocal, shouldPhoneBeDisabled, shouldEmailBeDisabled, phoneNum, email)) {
      try {
        // if we don't have email in the front, for example in Kakao x Wire Edit flow:
        // if the user choose the phone number as their primary contact.
        // => we take the email stored in the state to retrieve the email got from Kakao
        email = email || customer.customerContact.email;
        phoneNum = phoneNum || customer.customerContact.phone.number;
        const { cdbResult, oktaResult, webAppCheckResult, emailValidity } = await InformationService.checkSecondPointOfContact(preferredMethodOfContactLocal, customer, phoneNum, phonePrefix, email, brand, token);
        const { isExistingByContactInCDB, isExistingByOktaSocialLoginInCDB } = cdbResult;
        const { customerExistsInOkta } = oktaResult;
        if (emailValidity) {
          mp.validityEmailChecked(localStep, emailValidity);
        }

        // Avoid to trigger error in case of exhausted customer call
        if (webAppCheckResult !== CustomerCheckResponseEnum.DUPLICATION_ALERT) {
          // Update mix-panel super properties
          await mp.updateCustomerFlowGlobally(actualToken);
        }
        if (isExistingByContactInCDB || isExistingByOktaSocialLoginInCDB || customerExistsInOkta) {
          // duplication alert cases
          if (phoneNum || email) {
            formMethods.setError('duplicationAlert', { type: '', message: Utils.isLuceProspectSource(prospectSource) ? t('web.duplicateClient') : t('dcc.duplicate') });
          }
          mp.existingClient();
        } else if (webAppCheckResult === CustomerCheckResponseEnum.CREATION_AUTHORIZED && Utils.exist(emailValidity) && EmailValidityUtils.isEmailValidityStatusNotAccepted(emailValidity!)) {
          formMethods.setError('invalidEmailAlert', { type: '', message: t(EmailValidityUtils.getEmailValidityErrorKey(emailValidity!, Utils.isLuceProspectSource(prospectSource))) });
          window.scroll({ top: 0, behavior: 'smooth' });
        } else {
          actions.updateCustomerInformation({ ...informationInfos, emailValidity });
          mp.saveInformation(informationInfos, salesAdvisor);
          const nextPage = FlowService.nextPage(state, localStep);
          navigate(`/${token}/${nextPage}`);
        }
      } catch (err) {
        actions.updateErrorTypeOnError(err.response.data);
        if (err.response.data.errorType === ErrorType.TOO_MANY_2ND_POINT_OF_CONTACT_CHECKS) {
          setIsModalOpened(true);
          actions.updateIsSecondPointOfContactDisabled(true);
          if (preferredMethodOfContactLocal === PreferredMethodOfContact.EMAIL) {
            setPhoneUserInputValue('');
            setValue('phoneNum', '');
            setShouldPhoneBeDisabled(true);
            actions.updateCustomerInformation({ phoneNum: '' });
          }
          if (preferredMethodOfContactLocal === PreferredMethodOfContact.PHONE) {
            setEmailUserInputValue('');
            setValue('email', '');
            setShouldEmailBeDisabled(true);
            actions.updateCustomerInformation({ email: '' });
          }
        } else {
          navigate('/error');
        }
      }
    } else {
      actions.updateCustomerInformation(informationInfos);
      mp.saveInformation(informationInfos, salesAdvisor);
      const nextPage = FlowService.nextPage(state, localStep);
      navigate(`/${token}/${nextPage}`);
    }
  });

  if (brand && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, prospectSource }}>
        { isLoading ? <FullPageLoader />
          : (
            <PageContainer>
              {isModalOpened && (
                <ConfirmModal onClose={handleCloseModal} id="tooMany2ndPointOfContactAttemptsModal">
                  <p>{t('web.tooMany2ndPointOfContactAttempts')}</p>
                </ConfirmModal>
              )}
              <FormHeader
                numberOfSteps={FlowService.getTotalNumberOfSteps(state)}
                currentStep={currentStep}
                languages={languages}
                type={TYPE_DROPDOWN.DEFAULT}
                defaultLogoUrl={defaultLogoUrl}
              />
              <Title>{t(titleTranslationKey)}</Title>
              <FormProvider {...formMethods}>
                <form>
                  <FormContainer onKeyDown={(keyDownEvent) => Utils.validateForm(keyDownEvent, isValid, onSubmit)}>
                    <PhoneAndEmail
                      preferredMethodOfContact={preferredMethodOfContactLocal}
                      prospectSource={prospectSource}
                      formMethods={formMethods}
                      translatedCountries={translatedCountries}
                      phonePrefixLabel={phonePrefixLabelLocal}
                      setPhonePrefixLabel={setPhonePrefixLabelLocal}
                      phoneUserInputValue={phoneUserInputValue}
                      setPhoneUserInputValue={setPhoneUserInputValue}
                      emailUserInputValue={emailUserInputValue}
                      setEmailUserInputValue={setEmailUserInputValue}
                      phoneError={phoneError}
                      setPhoneError={setPhoneError}
                      emailError={emailError}
                      setEmailError={setEmailError}
                      showPhoneError={showPhoneError}
                      setShowPhoneError={setShowPhoneError}
                      showEmailError={showEmailError}
                      setShowEmailError={setShowEmailError}
                      localStep={PageID.INFORMATION}
                      shouldEmailBeDisabled={shouldEmailBeDisabled}
                      shouldPhoneBeDisabled={shouldPhoneBeDisabled}
                    />
                    {duplicationAlert && <ErrorMessage id="duplicationAlert">{duplicationAlert.message}</ErrorMessage>}
                    {invalidEmailAlert && <ErrorMessage id="riskyEmailAlert">{invalidEmailAlert.message}</ErrorMessage>}
                    <SectionTitle>{t('aboutClient.personalInfo')}</SectionTitle>
                    <IdentityForm formMethods={formMethods} setShowDateError={setShowDateError} showDateError={showDateError} />
                    { shouldDisplayAddress && (
                    <>
                      <SectionTitle>{t('aboutClient.residence')}</SectionTitle>
                      <Label htmlFor="Country">{t('dcc.countryLabel')}</Label>
                      <Field
                        name="selectCountry"
                        id="selectCountry"
                        ref={formMethods.register({ required: true, minLength: 1 })}
                        defaultValue={customerCountryCode ? t(`country.${customerCountryCode.toLowerCase()}`) : ''}
                        maxLength={0}
                        disabled
                      />
                      {Boolean(statesFromCurrentCountry.length) && (
                      <>
                        <Label htmlFor="State">{t('dcc.state')}</Label>
                        <Field
                          name="selectState"
                          id="selectState"
                          ref={formMethods.register({ required: true, minLength: 1 })}
                          defaultValue={t(customerStateCode ? `state.${customerStateCode}` : '')}
                          maxLength={0}
                          disabled
                        />
                      </>
                      )}
                      { // the normal if/else workflow breaks the mandatory state of fields. Due to the form control of react-hook-form ?
                      customerCountry && Utils.isAddressReversed(translations.lightLanguageCode, customerCountry.code)
                      && (
                      <>
                        <ZipCode prospectSource={prospectSource} />
                        <City prospectSource={prospectSource} />
                        <Street prospectSource={prospectSource} />
                        <PoBox currentCountry={customerCountry} />
                      </>
                      )
                    }
                      {
                      customerCountry && !Utils.isAddressReversed(translations.lightLanguageCode, customerCountry.code)
                      && (
                      <>
                        <Street prospectSource={prospectSource} />
                        <PoBox currentCountry={customerCountry} />
                        <City prospectSource={prospectSource} />
                        <ZipCode prospectSource={prospectSource} />
                      </>
                      )
                    }
                    </>
                    )}
                  </FormContainer>
                  <ButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.INFORMATION} onSubmit={onSubmit} />
                </form>
              </FormProvider>
            </PageContainer>
          )}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default Information;
