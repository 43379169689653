import Utils from '@utils/utils';
import { GlobalState } from 'little-state-machine';
import {
  Background,
  Country,
  CustomerLight,
  FeatureActivated,
  InformationAction,
  LightConfiguration,
  PageFlow,
  PrivacyConfiguration,
  ProspectSource,
  SalesAdvisor
} from '@/types';
import newStore, { authorizedPagesInitValue, emptyCustomer } from '@/littleStateMachine/state';
import {
  AlreadySentErrorResponseBody,
  BrandedTokenErrorResponseBody,
  LastPageVisitedResponseBody,
  TypedErrorResponseBody,
} from '@/types/brandedTokenError';
import { BrandCountryConfiguration } from '@/types/brandCountryConfiguration';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import { BackgroundEnum } from '@/types/enum/background';

export const updateToken = (state: GlobalState, actualToken: string) => ({
  ...state,
  actualToken,
});

export const updateAlreadyRegistered = (state: GlobalState, alreadyRegistered: boolean) => ({
  ...state,
  alreadyRegistered,
});

export const updateBackground = (state: GlobalState, payload: { background: Background, backgroundName: BackgroundEnum }) => ({
  ...state,
  backgrounds: {
    ...state.backgrounds,
    [payload.backgroundName]: {
      ...state.backgrounds[payload.backgroundName],
      backgroundUrl: payload.background.backgroundUrl,
      backgroundPosition: payload.background.backgroundPosition,
      buttonParameter: {
        ...state.backgrounds[payload.backgroundName]?.buttonParameter,
        ...(payload.background.buttonParameter?.fontColor && { fontColor: payload.background.buttonParameter?.fontColor }),
        ...(payload.background.buttonParameter?.backgroundColor && { backgroundColor: payload.background.buttonParameter?.backgroundColor }),
      },
      textParameter: {
        ...state.backgrounds[payload.backgroundName]?.textParameter,
        ...(payload.background.textParameter?.fontColor && { fontColor: payload.background.textParameter?.fontColor }),
      },
      logoParameter: {
        ...state.backgrounds[payload.backgroundName]?.logoParameter,
        ...(payload.background.logoParameter?.inversionRequired && { inversionRequired: payload.background.logoParameter?.inversionRequired }),
      },
      backgroundColor: payload.background.backgroundColor
    },
  },
});

export const resetBackground = (state: GlobalState, backgroundName: BackgroundEnum) => ({
  ...state,
  backgrounds: {
    ...state.backgrounds,
    [backgroundName]: {
      backgroundUrl: '',
      backgroundPosition: '',
    },
  },
});

export const updateDropdownSearchValuesPrefixNumber = (state: GlobalState, prefixNumber: string) => {
  const newState = state;
  newState.dropdownSearchValues.prefixNumber = prefixNumber;
  return newState;
};

export const updateLightLabels = (state: GlobalState, payload: LightConfiguration) => ({
  ...state,
  translations: {
    ...state.translations,
    privacyLanguageCode: payload.i18n.code,
    lightLanguageCode: payload.i18n.code,
    lightLabels: payload.i18n.labels,
  },
});

export const fetchStoreConfiguration = (state: GlobalState, payload: LightConfiguration) => {
  const sortedMusic = payload.event?.musics ? [...payload.event.musics].sort((a, b) => a.playOrder - b.playOrder) : [];
  const sortedCarouselImg = payload.event?.carouselComponent ? [...payload.event.carouselComponent].sort((a, b) => a.displayOrder - b.displayOrder) : [];
  const sortedVideo = payload.event?.videoComponent ? [...payload.event.videoComponent].sort((a, b) => a.displayOrder - b.displayOrder) : [];
  const sortedTextComponent = payload.event?.textComponent ? [...payload.event.textComponent].sort((a, b) => a.displayOrder - b.displayOrder) : [];
  const ctaComponent = payload.event?.ctaComponent ?? state.event.ctaComponent;
  const wallpaperComponent = payload.event?.wallpaperComponent ?? state.event.wallpaperComponent;
  return {
    ...state,
    flow: {
      ...state.flow,
      ...payload.pageFlow,
    },
    prospectSource: payload.prospectSource,
    brand: payload.brand,
    store: payload.store,
    customer: {
      ...state.customer,
      brand: payload.brand.code,
      brandEdw: payload.brand.edw,
      country: payload.store.countryCode,
      storeid: payload.store.storeCode,
    },
    ...(payload.prefilledClientData?.phoneNumber?.prefix && {
    }),
    countries: payload.countries
      .map((country: Country) => ({
        ...country,
        states: [...country.states].sort((a, b) => a.libelle.localeCompare(b.libelle)),
      }))
      .sort((a, b) => a.name.localeCompare(b.name)),
    translations: {
      ...state.translations,
      lightLanguageCode: payload.i18n.code,
      privacyLanguageCode: payload.i18n.code,
      lightLabels: payload.i18n.labels,
    },
    languages: [...payload.languagesAvailable].sort((a, b) => a.displayOrder - b.displayOrder),
    salesAdvisor: payload.sa,
    background: {
      backgroundUrl: payload.brand.backgroundUrl,
      backgroundPosition: payload.brand.backgroundPosition,
    },
    ...(payload.queueManagement && { queueManagement: payload.queueManagement }),
    ...(payload.event && { event: {
      ...payload.event,
      musics: sortedMusic,
      carouselComponent: sortedCarouselImg,
      videoComponent: sortedVideo,
      textComponent: sortedTextComponent,
      eventRedirectLink: payload.event.eventRedirectLink,
      ctaComponent,
      wallpaperComponent,
      isCustomJourney: payload.event.isCustomJourney,
      gifComponent: payload.event.gifComponent,
    } }),
    featuresActivated: payload.featuresActivated,
  };
};

export const updatePrefilledClientData = (state: GlobalState, payload: LightConfiguration) => {
  let phonePrefixCountryCode = '';
  const { prefilledClientData } = payload;
  if (prefilledClientData?.phoneNumber?.prefix) {
    const country = Utils.findWithMultipleConditions<Country>(payload.countries, (currentCountry) => currentCountry.code === prefilledClientData.phoneNumber.prefixCountry, (currentCountry) => currentCountry.phonePrefix === prefilledClientData.phoneNumber.prefix);
    if (country) phonePrefixCountryCode = country.code;
  }

  return {
    ...state,
    customer: {
      ...state.customer,
      ...(payload.prefilledClientData && {
        customerContact: {
          ...state.customer.customerContact,
          phone: {
            ...state.customer.customerContact.phone,
            // if prefix and prefix country don't match the same country, country will impose its prefix number
            ...(payload.prefilledClientData.phoneNumber?.prefix && { prefix: payload.prefilledClientData.phoneNumber.prefix }),
            ...(phonePrefixCountryCode && { prefixCountry: phonePrefixCountryCode }),
            ...(payload.prefilledClientData.phoneNumber?.number && { number: payload.prefilledClientData.phoneNumber.number }),
          },
          ...(payload.prefilledClientData.email && { email: payload.prefilledClientData.email }),
          address: {
            ...state.customer.customerContact.address,
            ...(payload.prefilledClientData?.address?.country && { country: payload.prefilledClientData.address.country }),
            ...(payload.prefilledClientData?.address?.postalCode && { zipcode: payload.prefilledClientData.address.postalCode }),
          },
        },
        customerInfo: {
          ...state.customer.customerInfo,
          firstname: payload.prefilledClientData.firstname,
          lastname: payload.prefilledClientData.lastname,
        },
      }),
    },
  };
};

export const updateCustomerCountry = (state: GlobalState, payload: { country: string, hasStates: boolean }) => {
  if (payload.hasStates) {
    // We add the State page in the flow pages and remove the State page from the forbidden pages
    FlowService.addPageInFlow(state, { name: PageID.STATE, stepValue: 0 }, FlowService.getIndex(state, PageID.COUNTRY) + 1);
  } else {
    // We add the State page in the forbidden pages and remove the State page from the flow
    FlowService.removePageFromFlow(state, PageID.STATE);
  }

  return {
    ...state,
    customer: {
      ...state.customer,
      customerContact: {
        ...state.customer.customerContact,
        address: {
          ...state.customer.customerContact.address,
          country: payload.country,
        },
      },
    },
  };
};

export const updateCustomerInformation = (state: GlobalState, payload: InformationAction) => ({
  ...state,
  customer: {
    ...state.customer,
    customerInfo: {
      ...state.customer.customerInfo,
      ...(payload.title !== undefined && { title: payload.title }),
      ...(payload.firstName !== undefined && { firstname: payload.firstName }),
      ...(payload.lastName !== undefined && { lastname: payload.lastName }),
      ...(payload.furiganaFirstName !== undefined && { furigana_firstname: payload.furiganaFirstName }),
      ...(payload.furiganaLastName !== undefined && { furigana_lastname: payload.furiganaLastName }),
      ...(payload.day !== undefined && { day: payload.day }),
      ...(payload.month !== undefined && { month: payload.month }),
      ...(payload.year !== undefined && { year: payload.year }),
      ...(payload.searchFieldNationality !== undefined && { nationality: payload.searchFieldNationality }),
    },
    customerContact: {
      ...state.customer.customerContact,
      address: {
        ...state.customer.customerContact.address,
        ...(payload.country !== undefined && { country: payload.country }),
        ...(payload.state !== undefined && { state: payload.state }),
        ...(payload.street !== undefined && { street: payload.street }),
        ...(payload.city !== undefined && { city: payload.city }),
        ...(payload.zipcode !== undefined && { zipcode: payload.zipcode }),
        ...(payload.pobox !== undefined && { pobox: payload.pobox }),
      },
      phone: {
        ...state.customer.customerContact.phone,
        ...(payload.phonePrefix !== undefined && { prefix: payload.phonePrefix }),
        ...(payload.phoneNum !== undefined && { number: payload.phoneNum }),
        ...(payload.prefixCountry !== undefined && { prefixCountry: payload.prefixCountry }),
      },
      socialAccounts: {
        ...state.customer.customerContact.socialAccounts,
        ...(payload.instagram !== undefined && { instagram: payload.instagram }),
        ...(payload.tiktok !== undefined && { tiktok: payload.tiktok }),
        ...(payload.weChat !== undefined && { weChat: payload.weChat }),
        ...(payload.weChatPlatform !== undefined && { weChatPlatform: payload.weChatPlatform }),
        ...(payload.okta !== undefined && { okta: payload.okta }),
        ...(payload.line !== undefined && { line: payload.line }),
      },
      ...(payload.email !== undefined && { email: payload.email }),
      ...(payload.emailValidity !== undefined && { emailValidity: payload.emailValidity }),
      ...(payload.preferredMethodOfContact !== undefined && { preferred: payload.preferredMethodOfContact }),
      ...(payload.privacies !== undefined && { privacies: payload.privacies }),
    },
  },
});

export const updateAlreadyExistingCustomer = (state: GlobalState, payload: CustomerLight) => {
  // we take the country the customer filled in the second page
  const newCustomerCountry = state.customer.customerContact.address.country;
  // if the country is not the same as the one in the database, we clear the address so that the address stays consistent
  const customerAddressChanged = payload.customerContact.address.country !== newCustomerCountry;
  const address = (customerAddressChanged && state.featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && state.prospectSource !== ProspectSource.UPDATE_MARKETING_CONSENT) ? {
    ...emptyCustomer.customerContact.address,
    country: newCustomerCountry,
    state: state.customer.customerContact.address.state
  } : {
    ...state.customer.customerContact.address,
    ...(payload.customerContact.address.country && { country: payload.customerContact.address.country }),
    ...(payload.customerContact.address.state && { state: payload.customerContact.address.state }),
    ...(payload.customerContact.address.pobox && { pobox: payload.customerContact.address.pobox }),
    ...(payload.customerContact.address.street && { street: payload.customerContact.address.street }),
    ...(payload.customerContact.address.zipcode && { zipcode: payload.customerContact.address.zipcode }),
    ...(payload.customerContact.address.city && { city: payload.customerContact.address.city }),
  };
  if (payload.customerContact.phone?.prefix) payload.customerContact.phone.prefixCountry = payload.customerContact.phone.prefix && Utils.mapPhonePrefixToCountry(state, payload.customerContact.phone.prefix);
  return ({
    ...state,
    customer: {
      ...state.customer,
      customerContact: {
        ...state.customer.customerContact,
        address,
        ...(payload.customerContact.email && { email: payload.customerContact.email }),
        phone: {
          ...state.customer.customerContact.phone,
          ...payload.customerContact.phone,
        },
        privacies: payload.customerContact.privacies,
      },
      customerInfo: {
        ...state.customer.customerInfo,
        ...(payload.customerInfo.month && { month: payload.customerInfo.month }),
        ...(payload.customerInfo.lastname && { lastname: payload.customerInfo.lastname }),
        ...(payload.customerInfo.year && { year: payload.customerInfo.year }),
        ...(payload.customerInfo.title && { title: payload.customerInfo.title }),
        ...(payload.customerInfo.nationality && { nationality: payload.customerInfo.nationality }),
        ...(payload.customerInfo.firstname && { firstname: payload.customerInfo.firstname }),
        ...(payload.customerInfo.day && { day: payload.customerInfo.day }),
        ...(payload.customerInfo.furigana_firstname && { furigana_firstname: payload.customerInfo.furigana_firstname }),
        ...(payload.customerInfo.furigana_lastname && { furigana_lastname: payload.customerInfo.furigana_lastname }),
      },
    },
  });
};

export const updateBrandCountryConfiguration = (state: GlobalState, payload: BrandCountryConfiguration) => {
  // We add the Address Page and Social Page in the forbidden Pages, and we remove the Address Page and Social Page of the flow
  FlowService.removePageFromFlow(state, PageID.ADDRESS);
  FlowService.removePageFromFlow(state, PageID.SOCIAL);

  if (!state.featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED) {
    if (payload.displayAddress) {
      FlowService.addPageInFlow(state, { name: PageID.ADDRESS, stepValue: 1 }, FlowService.getIndex(state, PageID.LEGAL));
    }
    if (payload.displaySocialAccounts) {
      FlowService.addPageInFlow(state, { name: PageID.SOCIAL, stepValue: 1 }, FlowService.getIndex(state, PageID.LEGAL));
    }
  }
  return {
    ...state,
    brandCountryConfiguration: payload,
  };
};

export const updatePrivacyConf = (state: GlobalState, payload: PrivacyConfiguration) => ({
  ...state,
  privacy: payload.privacy,
  translations: {
    ...state.translations,
    privacyLanguageCode: payload.privacyLanguageCode,
    privacyLabels: payload.labels,
  },
  privacyLanguages: [...payload.languagesAvailable].sort((a, b) => a.displayOrder - b.displayOrder),
});

export const updateBrandOnError = (state: GlobalState, payload: BrandedTokenErrorResponseBody) => ({
  ...state,
  brand: {
    ...state.brand,
    codeTriggeredByAnError: payload.codeTriggeredByAnError,
    defaultLogo: payload.defaultLogo,
    style: payload.style
  },
});

export const updateErrorTypeOnError = (state: GlobalState, payload: TypedErrorResponseBody) => ({
  ...state,
  error: {
    ...state.error,
    errorType: payload.errorType,
    isBackError: true,
  },
});

export const updateLastPageVisited = (state: GlobalState, payload: LastPageVisitedResponseBody) => ({
  ...state,
  error: {
    ...state.error,
    lastPageVisited: payload.lastPageVisited,
  },
});

export const updateFrontErrorSent = (state: GlobalState, payload: AlreadySentErrorResponseBody) => ({
  ...state,
  error: {
    ...state.error,
    isFrontErrorAlreadySent: true,
    frontErrorPageSent: payload.frontErrorPageSent,
  },
});

export const updateIsLoading = (state: GlobalState, isLoading: boolean) => ({
  ...state,
  isLoading,
});

export const cleanCustomerData = (state: GlobalState) => {
  const newState = state;
  const customerCountry = state.customer.customerContact.address.country;
  const customerCountryObject = state.countries.find((country: Country) => country.code === customerCountry);

  // reset some values that are disabled in certain countries/states if the user filled them and went back to change country/state
  if (!Utils.isFuriganaDisplayed(customerCountry)) {
    newState.customer.customerInfo.furigana_firstname = '';
    newState.customer.customerInfo.furigana_lastname = '';
  }
  if (customerCountryObject) {
    if (!customerCountryObject.states || customerCountryObject.states.length <= 0) {
      newState.customer.customerContact.address.state = '';
    }
    if (!customerCountryObject.displayPobox) {
      newState.customer.customerContact.address.pobox = '';
    }
  }
  if (!state.brandCountryConfiguration.displayNationality) {
    newState.customer.customerInfo.nationality = '';
  }
  return newState;
};

export const resetCustomerData = (state: GlobalState, isErrorPage: boolean) => ({
  ...state,
  customer: emptyCustomer,
  flow: {
    ...state.flow,
    authorizedPages: isErrorPage ? authorizedPagesInitValue : [...authorizedPagesInitValue, PageID.REGISTERED]
  }
});

export const emptyCustomerData = (state: GlobalState) => ({
  ...state,
  customer: {
    ...state.customer,
    customerContact: {
      ...emptyCustomer.customerContact,
      isSecondPointOfContactDisabled: state.customer.customerContact.isSecondPointOfContactDisabled,
      address: {
        ...emptyCustomer.customerContact.address,
        country: state.customer.customerContact.address.country,
        state: state.customer.customerContact.address.state
      }
    },
    customerInfo: emptyCustomer.customerInfo
  }
});

export const updatePreciselyAuthToken = (state: GlobalState, preciselyAuthToken: string) => ({
  ...state,
  precisely: {
    ...state.precisely,
    authToken: preciselyAuthToken
  }
});

export const updatePreciselyInstanceKey = (state: GlobalState, preciselyInstanceKey: string) => ({
  ...state,
  precisely: {
    ...state.precisely,
    instanceKey: preciselyInstanceKey
  }
});

export const updateFeaturesActivationAndPageFlow = (state: GlobalState, payload: { featuresActivated: { [key in FeatureActivated]: boolean }, pageFlow: PageFlow }) => ({
  ...state,
  featuresActivated: payload.featuresActivated,
  flow: {
    ...state.flow,
    flow: payload.pageFlow.flow,
    pages: payload.pageFlow.pages
  },
});

export const updateDateSendingOtp = (state: GlobalState, date: string) => ({
  ...state,
  dateSendingOtp: date
});

export const updateIsKakaoUserRetrieved = (state: GlobalState, isKakaoUserRetrieved: boolean) => ({
  ...state,
  isKakaoUserRetrieved
});

export const updateIsPoPAlreadySent = (state: GlobalState, isPoPAlreadySent: boolean) => ({
  ...state,
  isPoPAlreadySent
});

export const updateIsSecondPointOfContactDisabled = (state: GlobalState, isSecondPointOfContactDisabled: boolean) => ({
  ...state,
  customer: {
    ...state.customer,
    customerContact: {
      ...state.customer.customerContact,
      isSecondPointOfContactDisabled
    }
  }
});

export const updateMixPanelToken = (state: GlobalState, mixPanelToken: string) => ({
  ...state,
  mixPanelToken
});

export const updateSaleAdvisor = (state: GlobalState, payload: SalesAdvisor) => ({
  ...state,
  salesAdvisor: {
    ...state.salesAdvisor,
    firstName: payload.firstName,
    lastName: payload.lastName,
    phoneNumber: payload.phoneNumber,
    email: payload.email,
    vendorId: payload.vendorId
  }
});

export const resetStore = () => newStore;
