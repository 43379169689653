import { useStateMachine } from 'little-state-machine';
import Utils from '@utils/utils';
import { Labels } from '@/types/i18n';
import { Customer } from '@/types';

export type TranslationFunction = {
  t: (key: string, isPrivacy?: boolean, staticCustomer?: Customer) => string
}

const useTranslation = () => {
  const { state } = useStateMachine();

  return function t(key: string, isPrivacy?: boolean, staticCustomer?: Customer): string {
    const currentI18n: Labels = isPrivacy ? state.translations.privacyLabels : state.translations.lightLabels;
    // customer language is not available in fetched languages
    if (!currentI18n) {
      return key;
    }
    let translation = currentI18n[key];
    // translation map doesn't contain the translation key
    if (translation === undefined || translation === null) {
      return key;
    }
    // replace some placeholders in label
    const interpolation = Utils.buildInterpolation(state, staticCustomer);
    Object.keys(interpolation.placeholders).forEach((placeholder) => {
      translation = translation.replace(`${interpolation.interpolation.prefix}${placeholder}${interpolation.interpolation.suffix}`, interpolation.placeholders[placeholder]);
    });
    return translation;
  };
};

export default useTranslation;
