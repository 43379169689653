import React, { Dispatch, SetStateAction, useEffect } from 'react';
import Utils from '@utils/utils';
import {
  BirthDate,
  DayMonthWrapper,
  NamesWrapper,
  StyledSelect,
  StyleSlashDate,
} from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { ConnectForm, Label } from '@/common/components/form';
import { FirstName } from '@/common/components/form/information/FirstName';
import { FuriganaLastName } from '@/common/components/form/information/FuriganaLastName';
import { FuriganaFirstName } from '@/common/components/form/information/FuriganaFirstName';
import { Month } from '@/common/components/form/information/Month';
import { Day } from '@/common/components/form/information/Day';
import { Year } from '@/common/components/form/information/Year';
import ErrorMessage from '@/common/components/form/ErrorMessage';
import { Nationality } from '@/common/components/form/information/Nationality';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import useTranslatedCountries from '@/services/hooks/useTranslatedCountries';
import { LastName } from '@/common/components/form/information/LastName';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  formMethods: UseFormMethods<InformationFormProps>,
  setShowDateError: Dispatch<SetStateAction<boolean>>,
  showDateError: boolean,
}

export const IdentityForm: React.FC<Props> = ({ formMethods, setShowDateError, showDateError }) => {
  const { state } = useStateMachine();
  const translatedCountries = useTranslatedCountries();
  const { brand, customer, brandCountryConfiguration, prospectSource, translations, featuresActivated, alreadyRegistered } = state;
  const { mandatoryYearOfBirth } = brandCountryConfiguration;
  const customerCountry = customer.customerContact.address.country;
  const customerTitle = customer.customerInfo.title;
  useEffect(() => {
    (async function fetch() {
      // It prevents the submit button to be disabled if user goes back from the next page or if he refreshes the page.
      await formMethods.trigger();
    }());
  }, []);
  return (
    <ConnectForm>
      {({ register, t }: UseFormMethods<InformationFormProps> & TranslationFunction) => (
        <>
          {Utils.shouldShowTitleField(prospectSource, brandCountryConfiguration, customerTitle, featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED, alreadyRegistered) && (
          <>
            <Label htmlFor="title">{Utils.isGenderDisplayedInsteadOfSalutation(customerCountry, brand.code) ? t('gender.title') : t('dcc.salutation')}</Label>
            <StyledSelect
              name="title"
              id="title"
              parentRef={register({ required: true })}
              defaultValue={customer.customerInfo.title}
              autoComplete="honorific-prefix"
            >
              <option value="">-</option>
              { !Utils.isGenderDisplayedInsteadOfSalutation(customerCountry, brand.code)
                ? brandCountryConfiguration.courtesies.map((courtesy: string) => (
                  <option value={courtesy} key={courtesy}>{t(`courtesy.${courtesy}`)}</option>
                ))
                : (
                  <>
                    <option value="MR.">{t('gender.male')}</option>
                    <option value="MS.">{t('gender.female')}</option>
                    {brandCountryConfiguration.courtesies.includes('ABSENT') && <option value="ABSENT">{t('courtesy.ABSENT')}</option>}
                    {brandCountryConfiguration.courtesies.includes('PREFER NOT SAY') && <option value="PREFER NOT SAY">{t('courtesy.PREFER NOT SAY')}</option>}
                  </>
                )}
            </StyledSelect>
          </>
          )}
          {
            Utils.isFirstNameAndLastNameReversed(translations.lightLanguageCode, customer.customerContact.address.country)
              ? (
                <>
                  <NamesWrapper>
                    <LastName prospectSource={prospectSource} isDisabled={false} />
                    <FirstName prospectSource={prospectSource} isDisabled={false} />
                  </NamesWrapper>
                  <NamesWrapper>
                    <FuriganaLastName customerCountry={customer.customerContact.address.country} />
                    <FuriganaFirstName customerCountry={customer.customerContact.address.country} />
                  </NamesWrapper>
                </>
              ) : (
                <>
                  <NamesWrapper>
                    <FirstName prospectSource={prospectSource} isDisabled={false} />
                    <LastName prospectSource={prospectSource} isDisabled={false} />
                  </NamesWrapper>
                  <NamesWrapper>
                    <FuriganaFirstName customerCountry={customer.customerContact.address.country} />
                    <FuriganaLastName customerCountry={customer.customerContact.address.country} />
                  </NamesWrapper>
                </>
              )
          }
          {/* Date of birth component */}
          <Label htmlFor="day">{t('web.dateOfBirth')}</Label>
          <BirthDate>
            <DayMonthWrapper>
              {Utils.isMonthAndDayReversed(customer.customerContact.address.country)
                ? (
                  <>
                    <Month formMethods={formMethods} setShowDateError={setShowDateError} showDateError={showDateError} />
                    <StyleSlashDate>/</StyleSlashDate>
                    <Day formMethods={formMethods} setShowDateError={setShowDateError} showDateError={showDateError} />
                  </>
                ) : (
                  <>
                    <Day formMethods={formMethods} setShowDateError={setShowDateError} showDateError={showDateError} />
                    <StyleSlashDate>/</StyleSlashDate>
                    <Month formMethods={formMethods} setShowDateError={setShowDateError} showDateError={showDateError} />
                  </>
                )}
              <StyleSlashDate>/</StyleSlashDate>
            </DayMonthWrapper>
            <Year mandatoryYearOfBirth={mandatoryYearOfBirth} formMethods={formMethods} setShowDateError={setShowDateError} showDateError={showDateError} />
          </BirthDate>
          {showDateError && formMethods.errors.day?.type === 'dateFormatError' && <ErrorMessage>{t('dcc.dateOfBirthError')}</ErrorMessage>}
          {showDateError && formMethods.errors.day?.type === 'minimalAgeError' && <ErrorMessage>{t('web.minimalAge')}</ErrorMessage>}
          {translatedCountries && <Nationality translatedCountries={translatedCountries} displayNationality={brandCountryConfiguration.displayNationality} mandatoryNationality={brandCountryConfiguration.mandatoryNationality} defaultNationality={customerCountry} />}
        </>
      )}
    </ConnectForm>
  );
};
