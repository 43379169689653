import { ClassicText, StyledTitle, StyledTitleLuce } from '@pages/Registered/registered.styles';
import React from 'react';
import { useStateMachine } from 'little-state-machine';
import Utils from '@utils/utils';
import { useTranslation } from '@/services/hooks';
import { Customer, PreferredMethodOfContact, ProspectSource } from '@/types';
import { EventKey } from '@/types/enum/eventKey';
import { contactMethods } from '@/types/enum/contactMethods.enum';
import { CertificateOfCraftPage } from '@/types/enum/certificateOfCraftPage';

type Props = {
  staticCustomer: Customer,
  otpVerified: boolean,
  activeCertificateOfCraftPage: CertificateOfCraftPage
}

// We don't get the customer data from the state because those data are purged at registered page
export const RegisteredText = ({ staticCustomer, otpVerified, activeCertificateOfCraftPage }: Props) => {
  // Prepare translations
  const t = useTranslation();

  // Retrieve current state from store
  const { state } = useStateMachine();
  const { prospectSource, event, alreadyRegistered, brand, featuresActivated } = state;
  const { translationKeySuffixComponent } = event;

  if (featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED) {
    if (alreadyRegistered) {
      return (
        <>
          <StyledTitle>
            {t('web.welcomeCustomer.edit')}
          </StyledTitle>
          <ClassicText>{t('web.success.edit')}</ClassicText>
        </>
      );
    }
    if (!otpVerified) {
      return (
        <>
          <StyledTitle>
            {t('dcc.welcomeCustomer', false, staticCustomer)}
          </StyledTitle>
          <ClassicText>
            {t(`web.${staticCustomer.customerContact.preferred === PreferredMethodOfContact.PHONE ? contactMethods.SMS : contactMethods.EMAIL}WelcomeCustomer.create`)}
          </ClassicText>
        </>
      );
    }
  }

  switch (prospectSource) {
    case ProspectSource.NQP_WIRE:
      return (
        <StyledTitle>
          {t('web.nqpWelcomeCustomer')}
        </StyledTitle>
      );
    // Sa's phone
    case ProspectSource.LUCE:
      return (
        <StyledTitleLuce>
          {featuresActivated.IS_SA_QRCODE_DISPLAYED && t('web.luceRegisteredCustomer')}
          {featuresActivated.IS_WECHAT_MODAL_DISPLAYED && t('web.luceRegisteredCustomerWeChat')}
          {featuresActivated.ARE_JPTHTW_LINE_QRCODES_DISPLAYED && t('web.luceRegisteredCustomerLine')}
        </StyledTitleLuce>
      );
    case ProspectSource.LUCE_EVENT:
    case ProspectSource.LUCE_QUEUE:
    case ProspectSource.WEBDCC_SCAN:
      return (
        <>
          <StyledTitleLuce>
            {!Utils.isWebSuccessKeyDisplayed(brand.code) && t('dcc.welcomeCustomer', false, staticCustomer)}
            { !(featuresActivated.IS_SA_QRCODE_DISPLAYED || featuresActivated.IS_WECHAT_MODAL_DISPLAYED || featuresActivated.ARE_JPTHTW_LINE_QRCODES_DISPLAYED) && Utils.isWebSuccessKeyDisplayed(brand.code) && t('web.registeredCustomer')}
            {featuresActivated.IS_SA_QRCODE_DISPLAYED && t('web.luceRegisteredCustomer')}
            {featuresActivated.IS_WECHAT_MODAL_DISPLAYED && t('web.luceRegisteredCustomerWeChat')}
            {featuresActivated.ARE_JPTHTW_LINE_QRCODES_DISPLAYED && t('web.luceRegisteredCustomerLine')}
          </StyledTitleLuce>
          <ClassicText>{Utils.isWebSuccessKeyDisplayed(brand.code) && t('web.success')}</ClassicText>
        </>
      );
    case ProspectSource.UPDATE_ADDRESS:
    case ProspectSource.UPDATE_MARKETING_CONSENT:
      return (
        <StyledTitleLuce>
          {t('web.updateAddressRegisteredCustomer')}
        </StyledTitleLuce>
      );
    // certificate of craft flow
    case ProspectSource.WIRE_BVCRAFT_WHLSLR:
    case ProspectSource.WIRE_BVCRAFT_GIFT:
      if (activeCertificateOfCraftPage !== CertificateOfCraftPage.VALID_LEGAL) {
        return null;
      }
      return (
        <>
          <StyledTitle>
            {t('dcc.welcomeCustomer', false, staticCustomer)}
          </StyledTitle>
          <ClassicText>{Utils.isWebSuccessKeyDisplayed(brand.code) && t('web.success')}</ClassicText>
        </>
      );
    // customer's phone
    default:
      // todo: delete after end of Japan Wire customization ALW-2129
      if (featuresActivated.IS_JP_CUSTOM_CAROUSEL_ACTIVATED) {
        return (
          <>
            <StyledTitle>
              {t('dcc.welcomeCustomer')}
            </StyledTitle>
            <ClassicText id="registeredLabelJpCustomCarousel">
              {t(`web.pin.registered.${brand?.jpCustomCarousel?.translationKey}`)}
            </ClassicText>
          </>
        );
      }
      if (event.isCustomJourney) {
        if (alreadyRegistered) {
          return (
            <> </>
          );
        }
        // use right translationKey
        const translationKey = Utils.overloadTranslationKey(translationKeySuffixComponent, EventKey.THANKS_TEXT_KEY_SUFFIX);
        return (
          <StyledTitle>
            {t(`web.pin.thanksText.${translationKey}`)}
          </StyledTitle>
        );
      }
  }
  return (
    <>
      <StyledTitle>
        {t('dcc.welcomeCustomer', false, staticCustomer)}
      </StyledTitle>
      <ClassicText>{Utils.isWebSuccessKeyDisplayed(brand.code) && t('web.success')}</ClassicText>
    </>
  );
};
