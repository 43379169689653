import { ProspectSource } from '@/types/enum/prospectSource';
import { CustomerFlow } from '@/types/enum/customerFlow';
import { EmailValidityStatusEnum } from '@/types/enum/email-validity-status.enum';

export interface IPreciselyAuthTokenResponse {
    // ex: eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlNTFhNTg5ZjBmYjk1YWU5M2J...
    access_token: string,
}

export interface ICheckCustomerResponse {
    // false if customer already exists in CDB/IODS, true otherwise
    result: boolean,
    // true if customer exists only if search by contact not social
    existsOnlyViaContactSearch: boolean,
    // true if the searched customer is unique in kering database, false otherwise
    isUniqueInKeringDb: boolean,
    oktaResult: {
        // true if customer already exists in Okta, false otherwise
        customerExistsInOkta: boolean,
        // true if customer has verified is method of contact at okta account creation, false otherwise
        contactCertified: boolean
    }
}

export enum CustomerCheckResponseEnum {
    DUPLICATION_ALERT = 'DUPLICATION_ALERT',
    CREATION_AUTHORIZED = 'CREATION_AUTHORIZED',
    UPDATE_AUTHORIZED = 'UPDATE_AUTHORIZED'
}

export interface ICheckCustomerResponseV2 {
    cdbResult: {
        isExistingByContactInCDB: boolean;
        isUniqueByContactInCDB: boolean;
        isExistingByOktaSocialLoginInCDB: boolean;
        isUniqueByOktaSocialLoginInCDB: boolean;
    };
    oktaResult: {
        customerExistsInOkta: boolean;
        contactCertified: boolean;
    };
    webAppCheckResult: CustomerCheckResponseEnum;
    emailValidity?: EmailValidityStatusEnum;
}

export interface ICheckCustomerByNameAndContactResponse {
    checkByNameAndContactResult: CustomerCheckResponseEnum;
    oktaLogin: string;
}

export interface IResponseProspectSourceAndReCaptchaFrontKey {
    prospectSource: ProspectSource,
    reCaptchaFrontKey: string
}

export interface IResponseDynamicTokenCreated {
    dynamicToken: string
}

export interface IResponseQRCode {
    image: string,
    expiration: string
}

export interface IResponseLineQrCodes {
    jpQrCode: string,
    jpQrCodeExpiration: string,
    twQrCode: string,
    twQrCodeExpiration: string,
    thQrCode: string,
    thQrCodeExpiration: string,
}

export interface IResponseUpsertCustomer {
    success: true,
    creationType: 'sync' | 'async'
}

export interface IResponseCheckPoP {
    data: {
        alreadyExists: boolean
    }
}

export interface IResponseUploadPoP {
    data : {
        success: boolean,
    }
}

export interface ResponseGetCustomerFlow {
    data: {
        customerFlow: CustomerFlow
    }
}
