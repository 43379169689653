import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';
import { Select } from '@/common/components/form';
import { Field } from '@/common/components/form/Field';

export const StyleSlashDate = styled.div`
  font-size: 15pt;
  margin: 10px 3px;
  ${theme('brand', {
    AMQ: css`
      color: ${(props) => props.theme.colors.IDENTITY.labelColor.color};
    `,
    BV: css`
      color: ${(props) => props.theme.colors.IDENTITY.labelColor.color};
    `,
    GUCCI: css`
      color: ${(props) => props.theme.colors.IDENTITY.labelColor.color};
    `,
    BR: css`
      color: ${(props) => props.theme.colors.IDENTITY.labelColor.color};
    `,
    YSL: css`
      color: ${(props) => props.theme.colors.IDENTITY.labelColor.color};
    `
  })};
`;

export const DayMonthWrapper = styled.div`
  display: flex;
  width: 35vmax;
  min-width: 120px;
  @media only screen and (max-device-width : ${screenSizes.iphone5.width}){
    width: 30vmax;
  }
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    width: 25vmax;  
  }
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    width: 15vmax;
  }
`;

export const StyledSelect = styled(Select)`
  width: 75%;
`;

export const BirthDate = styled.div`
  display: flex;  
  ${theme('language', {
    ar: css`
       direction: rtl;
    `,
  })};
  }
`;

export const NameComponentWrapper = styled.div`
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    width: 48%;
  }
`;

export const NamesWrapper = styled.div`
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    display: flex;
    justify-content: space-between;
    
    ${theme('language', {
    ar: css`
       direction: rtl;
    `,
  })};
  }
`;

export const FieldDate = styled(Field)`
  ${theme('language', {
    ar: css`
      text-align: end;
      direction: ltr !important;
      ::placeholder {
        text-align: start;
        direction: rtl !important;
      }
    `,
  })};
  @media only screen and (min-width : ${screenSizes.galaxyFold.width}){
    ::placeholder {
    font-size: 7pt;
    }
  }
  @media only screen and (min-width : ${screenSizes.iphone5.width}){
    ::placeholder {
    font-size: 9pt;
    }
  }
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    ::placeholder {
    font-size: 12pt;
    }
  }
`;
