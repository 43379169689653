import {
  QrCodeText, SAClassicText, SADescriptionWrapper,
  SAQrCodeContainer, SAQrCodeImg,
  SAQrCodePreview, SaveButton,
  SaveSalesText, SaveSAText,
  StyledSAName, VcardLogo
} from '@pages/Registered/registered.styles';
import RegisteredService from '@pages/Registered/registered.service';
import QrCodeModal from '@common/components/modals/QrCodeModal';
import Loader from '@common/components/Loader';
import React, { useEffect, useState } from 'react';
import RequestService from '@services/request.service';
import { useStateMachine } from 'little-state-machine';
import { useTranslation } from '@services/hooks';
import { useParams } from 'react-router-dom';
import { SAContactInfo } from '@pages/Registered/components/SaleAdvisorContactInfo';
import api from '@/api';
import { VCard } from '@/types/vcard';
import { Customer, Logo } from '@/types';
import { Colors } from '@/types/colors';

type Props = {
  staticCustomer: Customer,
  defaultLogo: Logo,
  name: string,
  colors: Colors
}

export const SaveSaleAdvisor: React.FC<Props> = ({
  staticCustomer,
  defaultLogo,
  name,
  colors
}) => {
  // Prepare translations
  const t = useTranslation();

  // Retrieve current state from store
  const { state } = useStateMachine();
  const { store, salesAdvisor, featuresActivated } = state;
  const defaultLogoUrl = defaultLogo.logoUrl;

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  const [isVCardModalOpened, setIsVCardModalOpened] = useState<boolean>(false);
  const [vCardQrCodeImgSrc, setVCardQrCodeImgSrc] = useState<string>('');
  const vCardInformation: VCard = {
    brandName: name,
    firstName: salesAdvisor?.firstName || '',
    lastName: salesAdvisor?.lastName || '',
    phoneNumber: salesAdvisor?.phoneNumber || '',
    email: salesAdvisor?.email || '',
    storeName: store?.name || '',
    storeAddress: store?.address || '',
    storePhone: store?.phone || ''
  };

  useEffect(() => {
    (async function fetch() {
      const { data: vCardFormattedString } = await api.getVCardString(vCardInformation);
      const qrCodePayload = await RequestService.tryToFetchQrCodeWithBody(api.fetchVCardQrCode, { vCardFormattedString }, 5, 1000, token);
      setVCardQrCodeImgSrc(qrCodePayload?.image ?? '');
    }());
  }, []);

  return (
    <>
      {featuresActivated.IS_SA_QRCODE_DISPLAYED ? (
        <>
          <SaveSalesText>
            {t('web.shareSAQRCode')}
          </SaveSalesText>
          {vCardQrCodeImgSrc ? (<SAQrCodePreview id="vCardQrCodePreview" src={vCardQrCodeImgSrc} onClick={() => RegisteredService.handleClickVCardQrCode(setIsVCardModalOpened, vCardInformation, setVCardQrCodeImgSrc, token)} />) : null}
          {isVCardModalOpened && (
          <QrCodeModal onClose={() => setIsVCardModalOpened(false)}>
            {vCardQrCodeImgSrc ? (
              <SAQrCodeContainer>
                <QrCodeText>
                  <StyledSAName>
                    {`${salesAdvisor.firstName} ${salesAdvisor.lastName}`}
                  </StyledSAName>
                  <SADescriptionWrapper>
                    <SAClassicText>
                      {`${store.name}, ${store.address}`}
                    </SAClassicText>
                  </SADescriptionWrapper>
                  <SAContactInfo salesAdvisor={salesAdvisor} phoneIcon="/phoneIcon.svg" mailIcon="/mailIcon.svg" />
                </QrCodeText>
                <SAQrCodeImg id="vCardQrCode" src={vCardQrCodeImgSrc} alt="vCardQrCode" />
                <SaveSAText>
                  {t('web.saveSAWithName')}
                </SaveSAText>
                <VcardLogo id="brandLogo" src={defaultLogoUrl} alt="Logo" />
              </SAQrCodeContainer>
            ) : <Loader color={colors.COMMON.qrCodeLoader.color} />}
          </QrCodeModal>
          )}
        </>
      ) : (
        <>
          <SaveSalesText>
            {t('web.saveSa', false, staticCustomer)}
          </SaveSalesText>
          <form name="FormPost" method="post" action="/api/getVcard">
            <input type="hidden" name="brandName" value={name} />
            <input type="hidden" name="firstName" value={salesAdvisor.firstName} />
            <input type="hidden" name="lastName" value={salesAdvisor.lastName} />
            <input type="hidden" name="phoneNumber" value={salesAdvisor.phoneNumber} />
            <input type="hidden" name="email" value={salesAdvisor.email} />
            <input type="hidden" name="storeName" value={store.name} />
            <input type="hidden" name="storeAddress" value={store.address} />
            <input type="hidden" name="storePhone" value={store.phone} />
            <SaveButton id="saveButton" onClick={RegisteredService.handleClickSaveSA}>{t('web.save')}</SaveButton>
          </form>
        </>
      )}
    </>
  );
};
